import { useEffect, useState } from 'react';
import { RequestState } from '../../../constants/request-state';
import { manageStorageService } from '../../../services/manage-storage.service';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../actions';
import { ManageRedisKeyDetails } from '../../../types/management/ManageRedisKeyDetails';

export const useRedisCacheKeys = () => {
    const dispatch = useDispatch();
    const [keys, setRedisCacheKeys] = useState<ManageRedisKeyDetails[]>([]);
    const [requestStatus, setRequestStatus] = useState(RequestState.none);

    useEffect(() => {
        const getRedisCacheKeys = async () => {
            try {
                setRequestStatus(RequestState.request);
                const response = await manageStorageService.getRedisCacheKeys();
                setRedisCacheKeys(response);
                setRequestStatus(RequestState.success);
            } catch (e) {
                setRequestStatus(RequestState.failure);
                dispatch(errorActions.unexpectedError(e));
            }
        }

        getRedisCacheKeys();
    }, [dispatch])

    return {
        keys,
        requestStatus
    }
}
