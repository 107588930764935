import { useGetWebinar } from "../../effects/useGetWebinar";
import { isRequesting } from "../../utils";
import { Preloader } from "../common";
import { useGetCategoryArticles } from "./effects/useGetCategoryArticles";
import { useGetCategorySections } from "./effects/useGetCategorySections";
import { marketTrendsCategoryId } from "./constants";
import { ResourceContent } from "./ResourcesContent";
import { useAnchorScroll } from "../../effects/useAnchorScroll";

export const Resources = () => {
    const { requestStateGetWebinar, webinar } = useGetWebinar();
    const { requestStateGetCategorySections, sections } =
        useGetCategorySections(marketTrendsCategoryId);
    const { requestStateGetCategoryArticles, articles } =
        useGetCategoryArticles(marketTrendsCategoryId);
    // K2-1473 Resources:: Comment out the code for updating banner description via Zendesk
    // const { categories, categoriesRequestState } = useHelpResourcesCategories();
    // const category = useMemo(
    //     () => categories.find((c) => c.id === marketTrendsCategoryId),
    //     [categories]
    // );

    const isLoading =
        isRequesting(requestStateGetCategorySections) ||
        isRequesting(requestStateGetWebinar) ||
        isRequesting(requestStateGetCategoryArticles);

    useAnchorScroll(!isLoading);

    return (
        <Preloader inProgress={isLoading}>
            <ResourceContent
                webinar={webinar}
                webinarSections={sections}
                webinarArticles={articles}
            />
        </Preloader>
    );
};

