import { useAppSelector } from "../../effects/useAppSelector";
import { Rating } from "../../types/enums/Rating";
import { BaseInventorySummaryRow } from "./BaseInventorySummaryRow";

export function InventorySummaryRow() {
    const summary = useAppSelector(s => s.entities.allInventory.summary);
    const totalRecordNumber = useAppSelector(s => s.entities.allInventory.totalRecordNumber);

    if (!summary) {
        return null;
    }

    const { dealers, usdVolume, euroVolume, totalNew, totalTraded, totalInBwic, aaa, aa, a, bbb, bb, b, ccc, d, nr } = summary;

    const ratingCounter: Record<Rating, number> = {
        [Rating.AAA]: aaa,
        [Rating.AA]: aa,
        [Rating.A]: a,
        [Rating.BBB]: bbb,
        [Rating.BB]: bb,
        [Rating.B]: b,
        [Rating.CCC]: ccc,
        [Rating.D]: d,
        [Rating.NR]: nr,
        // Currently not used
        [Rating.AAAJR]: 0,
        [Rating.AAAX]: 0,
        [Rating.BBBJR]: 0,
    }

    return (
        <BaseInventorySummaryRow
            dealers={dealers}
            securitiesTraded={totalTraded}
            securitiesNew={totalNew}
            usdVolume={usdVolume}
            eurVolume={euroVolume}
            securitiesOnBWICs={totalInBwic}
            ratingCounter={ratingCounter}
            securitiesCounter={totalRecordNumber} />
    )
}