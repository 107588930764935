import { Currency } from "../../../types/enums/Currency";
import { Rating } from "../../../types/enums/Rating";
import { InventoryPosition } from "../../../types/inventory/InventoryPosition";
import { InventoryPositionStatus } from "../../../types/inventory/InventoryPositionStatus";

export const useBdInventoryCounters = (inventoryList: InventoryPosition[]) => {

    let securitiesCounter = 0;
    let securitiesTraded = 0;
    let securitiesNew = 0;
    let usdVolume = 0;
    let eurVolume = 0;
    let securitiesOnBWICs = 0;
    let ratingCounter: Record<Rating, number> = {
        AAA: 0,
        AA: 0,
        A: 0,
        BBB: 0,
        BBBJr: 0,
        BB: 0,
        B: 0,
        CCC: 0,
        D: 0,
        NR: 0,
        AAAX: 0,
        AAAJr: 0
    };

    const uniqueSecurities: number[] = [];

    inventoryList.forEach((i) => {
        if (i.status === InventoryPositionStatus.traded) {
            securitiesTraded++;
        }
        if (i.status === InventoryPositionStatus.added) {
            securitiesNew++;
        }
        if (i.currency === Currency.EUR) {
            eurVolume += i.size;
        }
        if (i.currency === Currency.USD) {
            usdVolume += i.size;
        }
        ratingCounter[i.rating]++;
        securitiesCounter++;

        if(!uniqueSecurities.includes(i.securityId) && i.scheduledOrBiddingBwicsCount) {
            securitiesOnBWICs++;
        }

        uniqueSecurities.push(i.securityId);
    })

    return { securitiesTraded, securitiesNew, usdVolume, eurVolume, securitiesOnBWICs, ratingCounter, securitiesCounter };
}