import { moneyUtils } from "../../utils/money.utils";
import { roles } from "../../constants/roles";
import { user } from "../../user";
import { SummaryRow } from "../common/summary-row/SummaryRow";
import { RequiredFeature } from "../access/RequiredFeature";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { SubscribeLink } from "../access/BlockedFeatureText";
import { SummaryRowGroup } from "../common/summary-row/SummaryRowGroup";
import { SummaryRowItem } from "../common/summary-row/SummaryRowItem";
import { Rating } from "../../types/enums/Rating";

interface BaseInventorySummuryRowProps {
    dealers?: number;
    securitiesTraded: number,
    securitiesNew: number,
    usdVolume: number,
    eurVolume: number,
    securitiesOnBWICs: number,
    ratingCounter: Record<Rating, number>,
    securitiesCounter: number
}

export function BaseInventorySummaryRow({ dealers, securitiesTraded, securitiesNew, usdVolume, eurVolume, securitiesOnBWICs, ratingCounter, securitiesCounter }: BaseInventorySummuryRowProps) {
    const isBd = user.hasRoles(...roles.bd());

    const getVolumeValues = () => {
        const usd = usdVolume ? moneyUtils.amountToMM(usdVolume, true) : null;
        const eur = eurVolume ? moneyUtils.amountToMM(eurVolume, true) : null;

        return [usd, eur];
    }
    const inventory = () => isBd ? "Inventory" : "Dealer Inventory";

    return (
        <SummaryRow>
            <RequiredFeature
                inline
                feature={SubscriptionFeature.InventoryCanViewSummaryRow}
                text={<><SubscribeLink text="Subscribe to the Pro plan" />&nbsp;to see {inventory()} stats.</>}
            >
                {
                    !isBd &&
                    <>
                        <SummaryRowGroup groupName="Dealers">
                            <SummaryRowItem label="Count" tooltip="Number of Broker-Dealers companies." value={dealers?.toLocaleString()} />
                        </SummaryRowGroup>
                        <div className="vertical-divider" />
                    </>
                }
                <SummaryRowGroup groupName="Securities">
                    <SummaryRowItem label="Count" tooltip="Total number of bonds." value={securitiesCounter?.toLocaleString()} />
                    <SummaryRowItem label="Volume $/€" tooltip="Total volume of USD/EUR bonds." values={getVolumeValues()} />
                    <SummaryRowItem label="Traded" tooltip="Number of securities marked as traded." value={securitiesTraded?.toLocaleString()} />
                    <SummaryRowItem label="New" tooltip="Number of new securities." value={securitiesNew?.toLocaleString()} />
                    <SummaryRowItem label="In BWICs" tooltip="Number of securities that participate in Scheduled/Bidding BWICs." value={securitiesOnBWICs?.toLocaleString()} />
                    <SummaryRowItem label="AAA" tooltip="Number of bonds with AAA rating." value={ratingCounter.AAA?.toLocaleString()} />
                    <SummaryRowItem label="AA" tooltip="Number of bonds with AA rating." value={ratingCounter.AA?.toLocaleString()} />
                    <SummaryRowItem label="A" tooltip="Number of bonds with A rating." value={ratingCounter.A?.toLocaleString()} />
                    <SummaryRowItem label="BBB" tooltip="Number of bonds with BBB rating." value={ratingCounter.BBB?.toLocaleString()} />
                    <SummaryRowItem label="BB" tooltip="Number of bonds with BB rating." value={ratingCounter.BB?.toLocaleString()} />
                    <SummaryRowItem label="B" tooltip="Number of bonds with B rating." value={ratingCounter.B?.toLocaleString()} />
                    <SummaryRowItem label="CCC" tooltip="Number of bonds with CCC rating." value={ratingCounter.CCC?.toLocaleString()} />
                    <SummaryRowItem label="D" tooltip="Number of bonds with D rating." value={ratingCounter.D?.toLocaleString()} />
                    <SummaryRowItem label="NR" tooltip="Number of bonds with NR rating." value={ratingCounter.NR?.toLocaleString()} />
                </SummaryRowGroup>
            </RequiredFeature>
        </SummaryRow>
    )
}