import { useEffect, useMemo, useRef } from 'react';
import { keys } from 'lodash';
import moment from 'moment/moment';
import { useHistory, useLocation, useParams } from 'react-router';
import { routes } from '../../../constants';
import { HelpResourceArticle } from '../../../types/help-resources/HelpResourceArticle';
import { HelpResourceSection } from '../../../types/help-resources/HelpResourceCategory';
import { MarketTrendsMonthFilter } from './MarketTrendsMonthFilter';
import { MarketTrendsSection } from './MarketTrendsSection';
import { MarketTrendsYearFilter } from './MarketTrendsYearFilter';

const monthsList = moment.months();

const groupMarkedTrendsSections = (sections: HelpResourceSection[]): MarketTrendsStructure => {
    const structure: MarketTrendsStructure = {};
    sections.forEach(section => {
        const [month, yearString] = section.name.split(' ');
        const year = Number(yearString);

        if (!structure[year]) {
            structure[year] = [];
        }

        if (monthsList.indexOf(month) !== -1 && structure[year].findIndex(i => i.month === month) === -1) {
            structure[year].push({ month });
            structure[year].sort((a, b) => monthsList.indexOf(b.month) - monthsList.indexOf(a.month));
        }
    })
    return structure;
};

type MarketTrendsStructure = { [year: number]: { month: string }[] };

interface MarketTrendsWebinarsContentProps {
    sections: HelpResourceSection[];
    articles: HelpResourceArticle[];
    monthInScroll: string | null;
    scrollToStart: () => void;
}

export function MarketTrendsWebinarsContent({ sections, articles, monthInScroll, scrollToStart }: MarketTrendsWebinarsContentProps) {
    const initialized = useRef(false);
    const history = useHistory();
    const { year } = useParams<{ year: string }>();
    const { hash } = useLocation();
    const selectedYear = Number(year);

    const structure = useMemo(() => groupMarkedTrendsSections(sections), [sections]);
    const yearList = useMemo(() => keys(structure).map(year => Number(year)).sort().reverse(), [structure]);

    const yearStructure = structure[selectedYear];

    const selectedMonth = monthInScroll || (yearStructure ? yearStructure[0].month : '');

    useEffect(() => {
        if (yearList[0] && structure && !initialized.current) {
            history.replace(`${routes.resourcesWebinarUrl(selectedYear || yearList[0])}${hash || ''}`);
            initialized.current = true;
        }
    }, [selectedYear, yearList, history, structure, hash])

    const handleYearChanged = (year: number) => {
        history.push(routes.resourcesWebinarUrl(year));
        scrollToStart();
    }

    return (
        <>
            <MarketTrendsYearFilter years={yearList} onSelectedYearChange={handleYearChanged} />
            <MarketTrendsMonthFilter months={yearStructure} selectedMonth={selectedMonth} />
            <div className="webinars-list">
                {
                    sections.filter(s => s.name.indexOf(String(selectedYear)) > -1).map(s =>
                        <MarketTrendsSection
                            key={s.id}
                            section={s}
                            articles={articles.filter(a => a.section_id === s.id)}
                        />
                    )
                }
            </div>
        </>
    )
}
