import React from 'react';
import { useClearRedisCache } from './useClearRedisCache';
import { useRedisCacheKeys } from './useRedisCacheKeys';
import { Table } from '../../bidding/common/table';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { ManageRedisKeyDetails } from '../../../types/management/ManageRedisKeyDetails';
import { Preloader, OnHoverTooltip, CopyToClipboard, EmptyPlaceholder } from '../../common';

import { isRequestSuccess, isRequestFailed, isRequesting, isRequestNone } from '../../../utils';
import { StatusMessageSection } from '../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../types/state/NotificationState';


export const ManageCleanRedisCache: React.FC = () => {
    const { requestState, clearCache } = useClearRedisCache();
    const { keys, requestStatus } = useRedisCacheKeys();

    const renderResponseStatus = () => {
        if(isRequestSuccess(requestState)) {
            return (
                <StatusMessageSection type={StatusMessageSectionType.Success} iconOnly>
                    Cache is cleared successfully
                </StatusMessageSection>
            )
        }
        if(isRequestFailed(requestState)) {
            return (
                <StatusMessageSection type={StatusMessageSectionType.Error} iconOnly>
                    Failed to clear the cache
                </StatusMessageSection>
            )
        }
       return ''
    }

    const getTableColumns = () => {
        const columns: IColumnDefinition<ManageRedisKeyDetails>[] = [
            {
                columnKey: 'key',
                renderColumnHeaderContent: () => 'Key',
                renderColumnContent: entity => (
                    <OnHoverTooltip overlay={entity.key}>
                        {entity.key}
                    </OnHoverTooltip>
                ),
                className: 'data-list-cell-xxxl-flexible'
            }, {
                columnKey: 'copyTo',
                renderColumnHeaderContent: () => '',
                renderColumnContent: entity => (
                    <CopyToClipboard text={entity.key} />
                ),
                className: 'data-list-cell-icon'
            },
        ]
        return columns.map(c => new ColumnBuilder(c))
    }

    return (
        <div className="container manage-cnt">
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Storage</h1>
                    <button className="btn btn-ghost flex-none" onClick={clearCache} disabled={isRequestSuccess(requestState)}>
                        Clear cache
                    </button>
                    {renderResponseStatus()}
                </div>
            </div>
            <Preloader inProgress={isRequesting(requestStatus) || isRequestNone(requestStatus)}>
                {keys.length ? (
                    <Table
                        className="data-list-striped"
                        columns={getTableColumns()}
                        dataItems={keys}
                    />
                ) : (
                    <EmptyPlaceholder text="No keys" />
                )}
            </Preloader>
        </div>
    )
}
